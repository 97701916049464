import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import Styles from './styles.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Skeleton, SkeletonCircle, SkeletonText, Stack} from '@chakra-ui/react'
import AreaChartComponent from '../../../../../Components/Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import TabLoader from '../../../../../Components/Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../../../Components/Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../DeleteMetric/DeleteMetric';
import PieChartComponent from '../../../../../Components/Modules/Charts/PieChartComponent';

const Financials = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [financialMetrics, setFinancialMetrics] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)

  const [opsMetrics, setOpsMetrics] = useState()
  const loopCardRef = useRef(null);
  const [matchedData, setMatchedData] = useState([]);

  const [metricLength, setMetricLength] = useState(1);
  const [matchedDataLength, setMatchedDataLength] = useState(1);

  useEffect(() => {
    setIsLoading(true)
    API.get(`/get-pillar-action`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(actions=>{
      if(props.selectedType !== null){
        API.get(`/metric-filter?query=financial&fiscal_${props.selectedType}=${props.selectedValue}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }   
        }).then(metrics=>{
          const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
            metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
          );
          setMatchedData(matchedDataArray);
          setFinancialMetrics(metrics.data)
          setMetricLength(metrics.data.length)
          setMatchedDataLength(matchedDataArray.length)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else{
        API.get(`/metric?query=financial`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(metrics=>{
          const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
            metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
          );
          setMatchedData(matchedDataArray);
          setFinancialMetrics(metrics.data)
          setIsLoading(false)
          setMetricLength(metrics.data.length)
          setMatchedDataLength(matchedDataArray.length)
        }).catch(error=>{
          setIsLoading(false)
        })
      }
    })
  },[props.pillarId, props.selectedType, props.selectedValue, token])

  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };

  const currentYear = new Date().getFullYear();

  const getUpatedMetric = (metric) =>{
    setFinancialMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setNewLoaderLoading(loding)
  }

  const customSort = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    const numA = parseInt(nameA.match(/\d+/), 10);
    const numB = parseInt(nameB.match(/\d+/), 10);
    if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
      return numA - numB;
    }
    return nameA.localeCompare(nameB);
  };
  
  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {/* {matchedDataLength < 1 ? <Box fontSize={'14px'}> We couldn’t find any data matching your criteria. Please try again with different filters or check back later. </Box> : null} */}
      {
        props.isLoading || isLoading ? 
          <>
            <Stack>
              <Skeleton height='20px' />
            </Stack>
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'} className='loopCard' ref={loopCardRef}>
              <Skeleton height='200px' />
              <Skeleton height='200px' />
              <Skeleton height='200px' />
              <Skeleton height='200px' />
            </SimpleGrid>
          </>
        : 
        <>
          <Box> <PieChartComponent metricData={financialMetrics && financialMetrics} /> </Box>
          <Box>
            {
              matchedData && matchedData.sort(customSort).map(action  => 
                <Box className='metricCard'>
                  <Heading textTransform='capitalize' className={Styles.cardTitleSub}>
                    {action.name}
                  </Heading>
                  <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                    {
                      financialMetrics && financialMetrics.map(metric => 
                        metric.action_id === action.id ?
                          <>
                            {
                              metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                              metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null :
                              <>
                                <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                                  <Box className='frontCard'>
                                    <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                                    {props.edit ? <DeleteMetric id={metric.id} type={'operational'} getUpatedMetric={getUpatedMetric} isLoaderloading={isLoaderloading} /> : null }
                                    <Box className='clearfix'></Box>
                                    <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                                      <Box p={'10px'}>
                                        <Heading as='h5' size='sm' className={Styles.metricTitle + " headingHeight"}>
                                          {metric.name}
                                        </Heading>
                                        <Box className='metricAlignment'>
                                          <Box className='metricCount'>
                                            {
                                              metric?.chart_value?.chart_top_value?.length > 0 ? 
                                                <>
                                                  {
                                                    metric.chart_value.chart_top_value.map(item =>
                                                      item.value !== null ?
                                                        <>
                                                          { 
                                                            metric.datatype === 'money' ? "$" : null
                                                          }
                                                          <NumberFormat number={item.value} />
                                                          {
                                                            metric.datatype === 'percent' ? "%" : null
                                                          }
                                                        </> 
                                                      : null
                                                    )
                                                  }
                                                </> 
                                              : null
                                            }
                                          </Box>
                                          <AreaChartComponent chartData={metric?.chart_value?.result} />
                                        </Box>
                                      </Box>
                                    </Link>
                                  </Box>
                                  <Box className='backCard'>
                                    <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                                    <Box className='clearfix'></Box>
                                    {
                                      metric.description === '<p><br></p>' ? <Box className='noDesc'> No description </Box> : metric.description === null ? 'No description' : parse(metric.description)
                                    }
                                  </Box>
                                </Box>
                              </>
                            }
                          </>
                        : null
                      )
                    }
                  </SimpleGrid>
                </Box>
              )
            }
          </Box>
        </>
      }
    </>
  )
}


export default Financials