import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './allmetrics.module.css'
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, FormControl, Switch, Card, CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import AreaChartComponent from '../../../Modules/Charts/AreaChartComponent';
import PieChartComponent from '../../../Modules/Charts/PieChartComponent';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import TabLoader from '../../../Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../DeleteMetric/DeleteMetric';
import CanvasChart from '../../../Modules/Charts/CanvasChart/CanvasChart';

const AllMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [allMetrics, setAllMetrics] = useState()
  const {id} = useParams();
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)
  const [matchedData, setMatchedData] = useState([]);
  const [metricLength, setMetricLength] = useState(1);
  const [matchedDataLength, setMatchedDataLength] = useState(1);

  useEffect(() => {

    setIsLoading(true)
    API.get(`/pillar?module_id=${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      API.get(`/action?pillar_id=${response.data.id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((actions) => {
        if(props.selectedType !== null){
          API.get(`/metric-filter?fiscal_${props.selectedType}=${props.selectedValue}&pillar_id=${response.data.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }   
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setAllMetrics(metrics.data)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        }else{
          API.get(`/metric?pillar_id=${response.data.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setAllMetrics(metrics.data)
            setIsLoading(false)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
          }).catch(error=>{
            setIsLoading(false)
          })
        }
      }).catch(error=>{
      })
    })
  },[props.pillarId, props.selectedType, props.selectedValue, id, token])
  
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };

  const getUpatedMetric = (metric) =>{
    setAllMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setAllMetrics(loding)
  }

  const customSort = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    const numA = parseInt(nameA.match(/\d+/), 10);
    const numB = parseInt(nameB.match(/\d+/), 10);
    if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
      return numA - numB;
    }
    return nameA.localeCompare(nameB);
  };

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      {matchedDataLength < 1 ? <Box fontSize={'14px'}> We couldn’t find any data matching your criteria. Please try again with different filters or check back later. </Box> : null}
      <Box>
        <PieChartComponent metricData={allMetrics && allMetrics} />
      </Box>
      <Box>
        {
          matchedData && matchedData.sort(customSort).map(action  => 
            <Box className='metricCard'>
              <Heading textTransform='capitalize' className={Styles.cardTitleSub}>
                {action.name}
              </Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                {
                  allMetrics && allMetrics.map(metric => 
                    metric.action_id === action.id ?
                      <>
                        {
                          metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                          metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null :
                          <>
                            <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                              <Box className='frontCard'>
                                <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                                {props.edit ? <DeleteMetric id={metric.id} type={'operational'} getUpatedMetric={getUpatedMetric} isLoaderloading={isLoaderloading} /> : null }
                                <Box className='clearfix'></Box>
                                <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                                  <Box p={'10px'}>
                                    <Heading as='h5' size='sm' className={Styles.metricTitle + " headingHeight"}>
                                      {metric.name}
                                    </Heading>
                                    <Box className='metricAlignment'>
                                      <Box className='metricCount'>
                                        {
                                          metric?.chart_value?.chart_top_value?.length > 0 ? 
                                            <>
                                              {
                                                metric.chart_value.chart_top_value.map(item =>
                                                  item.value !== null ?
                                                    <>
                                                      { 
                                                        metric.datatype === 'money' ? "$" : null
                                                      }
                                                      <NumberFormat number={item.value} />
                                                      {
                                                        metric.datatype === 'percent' ? "%" : null
                                                      }
                                                    </> 
                                                  : null
                                                )
                                              }
                                            </> 
                                          : null
                                        }
                                      </Box>
                                      <AreaChartComponent chartData={metric?.chart_value?.result} />
                                    </Box>
                                  </Box>
                                </Link>
                              </Box>
                              <Box className='backCard'>
                                <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                                <Box className='clearfix'></Box>
                                {
                                  metric.description === '<p><br></p>' ? <Box className='noDesc'> No description </Box> : metric.description === null ? 'No description' : parse(metric.description)
                                }
                              </Box>
                            </Box>
                          </>
                        }
                      </>
                    :null
                  )
                }
              </SimpleGrid>
            </Box>
          )
        }
      </Box>
    </>
  )
}

export default AllMetrics





