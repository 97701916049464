import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, FormControl, Switch } from "@chakra-ui/react";
import Permissons from "../../Modules/Permissions/Permissons";
import Styles from "./Breadcrumbsnav.module.css";
import { EditContext } from "../../../Context/BreadcrumbsContext";
import API from "../../../Services/API";

function BreadCrumbsNav(props) {
  const token = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [pageHeading, setPageHeading] = useState();
  const {edit, setEdit } = useContext(EditContext);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [permissionType, setPermissionType] = useState(() => {
    // Initialize state from localStorage
    const storedPermission = localStorage.getItem("permissionType");
    return storedPermission || ""; // Default to empty string if not found
  });

  const [newPath, setNewPath] = useState(null)
  const [impctglry, setImpctglry] = useState(null)

  useEffect(() => {
    const decodedPathname = decodeURIComponent(location.pathname);
    const segments = decodedPathname.split('/'); // Split the path by '/'
    const windowLocation = window.location.pathname;
    const metricPath = windowLocation.split('/').slice(0, 2).join('/');
    const ImpactMetricSegments = windowLocation.split('/');
    const getImpactMetricPath = `/${ImpactMetricSegments[2]}`
    
    setNewPath(metricPath)
    if (segments.length > 4) {
      setImpctglry(segments[4])
    }
    
    API.get(`/project-modules`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      const windowLocation = window.location.pathname;
      const metricPath = windowLocation.split('/').slice(0, 2).join('/');
      const faqPath = windowLocation.split('/').slice(0, 3).join('/');
      if(windowLocation === "/" || windowLocation === "/dashboard"){
        const homeData = response.data.find((module) => module.unique_name === 'home');
        API.get(`/get-specific-modules?module_id=${homeData?.id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(metricPath === '/metric'){
        const windowLocation = window.location.pathname;
        const pathParts = windowLocation.split('/');
        const firstExtractedId = pathParts[2];
        API.get(`/get-specific-modules?metric_id=${firstExtractedId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(faqPath === '/help/faq'){
        const help = windowLocation.split('/')
        const helpId = help[3];
        API.get(`/get-specific-modules?module_id=${helpId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(metricPath === '/portfolio'){
        const portfolio = windowLocation.split('/')
        const portfolioId = portfolio[3];
        API.get(`/get-specific-modules?module_id=${portfolioId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else{
        const pathParts = windowLocation.split('/');
        const firstExtractedId = pathParts[2];
        const secondExtractedId = pathParts[4];
        const pillarId = parseInt(firstExtractedId)
        const actionId = parseInt(secondExtractedId)
        if((getImpactMetricPath !== '/impactmetrics') ){
          if(pillarId){
            API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then(response => {
              const permission = response?.data?.permission;
              setPermissionType(permission);
            }).catch(error=>{
      
            })
          }else if(actionId){
            API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then(response => {
              const permission = response?.data?.permission;
              setPermissionType(permission);
            }).catch(error=>{
      
            })
          }
        }
      }
    }).catch(error=>{

    })
  }, [location.pathname]);

  const editPage = (event) => {
    setEdit(event.target.checked)
  };

  const containsOnlyNumbers = (str) => {
    return /^\d+$/.test(str);
  };

  return (
    <>
      <nav aria-label="breadcrumb" style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <ol className="breadcrumb" style={{ margin: "0" }}>
          <li className="breadcrumb-item" style={{background:"transparent"}}>
            <Link to="/">Home</Link>
          </li>
          {pathnames.map((path, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            return path !== "action" &&
              path !== "metric" &&
              path !== "article" &&
              path !== "asio" &&
              path !== "help" &&
              path !== "pillar" &&
              path !== "initiative" &&
              path !== "qtr" &&
              path !== "yeargoal" &&
              path !== "fy" &&
              path !== "tg" &&
              path !== "viewpost" &&
              path !== "portfolio" &&
              path !== "ig" &&
              path !== "region" &&
              path !== "country" &&
              path !== "team" &&
              path !== "faq" &&
              path !== "view" &&
              path !== "impactmetrics" &&
              

              containsOnlyNumbers(decodeURIComponent(path)) === false ? (
              <li
                key={index}
                className={`breadcrumb-item ${isLast ? "active" : ""}`}
              >
                {isLast ? (
                  <span className={Styles.cap}>
                    {decodeURIComponent(path) === "mytasks"
                      ? "My Tasks"
                      : decodeURIComponent(path)}
                  </span>
                ) : (
                  <Link to={routeTo}>{decodeURIComponent(path)}</Link>
                )}
              </li>
            ) : null;
          })}
        </ol>
        <ol style={{ margin: 0 }}>
          <FormControl display="flex" alignItems="center" className="editMain permissionUpdate" style={{ margin: 0 }}>
            {
              (impctglry === 'Impact gallery') || 
              (decodeURIComponent(location.pathname.split('/').pop()) === 'profile') ||
              (decodeURIComponent(location.pathname.split('/').pop()) === 'Impact Gallery') ||
              (permissionType && permissionType !== 'viewer') ? 
              (
                <Switch
                  id="edit-toggle"
                  size="md"
                  isChecked={edit}
                  onChange={editPage}
                />
              ) : null 
            }
          </FormControl>
        </ol>
      </nav>
    </>
  );
}

export default BreadCrumbsNav;
